@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;1,300;1,400&display=swap");

/*  Global style */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
}

li {
    list-style: none;
}

p {
    font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

body {
    background-color: #001b47;
}

/*  global style end */

/* navbar-START */

#header-background {
    /*background-image: linear-gradient(180deg, #011B48 0%, rgba(20, 20, 20, 0.39) 48.44%, #011B48 100%),url(./header-background.png);*/
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar {
    display: flex;
    max-width: 1232px;
    padding: 50px 0 15px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 0 auto;
}

.navbar ul li {
    list-style: none;
    display: inline-block;
    margin: 0px 15px;
}

.navbar ul li a {
    font-size: 22px;
    line-height: 25px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
}

.ctn {
    margin: 0px 0 0 15px;
    border-color: rgba(255, 255, 255, 0.8);
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    text-transform: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: rgba(255, 255, 255, 0);
    color: rgba(255, 255, 255, 0.8);
    padding: 11px 23px;
    line-height: 16px;
    font-size: 16px;
}

/* Header start */
.hero-section-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    max-width: 1232px;
    margin: auto;
    padding: 30px 0;
}

.hero-background {
    background-image: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.39) 48.44%,
            #011b48 100%
        ),
        url(./bgsword.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.hero-section-text h3 {
    font-size: 42px;
    line-height: 65px;
    margin-bottom: 30px;
    font-weight: bold;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
}

.hero-section-text {
    margin-top: 35px;
    padding-right: 90px;
}

.hero-section-text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    padding-bottom: 35px;
    color: #ffffff;
    padding: 0 40px 35px 0;
}

.hero-section-text a {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 70px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px;
}

.hero-text-img {
    background: #4685ff50;
    border-radius: 7.73376px;
    width: 509px;
    padding: 40px;
    text-align: center;
}

.hero-img-time {
    text-align: center;
    /* padding: 25px 0 10px; */
}

.hero-img-time p {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: left;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.mintPrice {
    display: inline-block;
    align-items: center;
    justify-content: space-between;
}

.priceTitle p {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    /*letter-spacing: 0.05em;*/
    color: #ffffff;
}

.priceTitle p span {
    font-size: 20px;
}

.mint {
    text-align: center;
    margin: 80px 0;
}

.mint a {
    line-height: 17px;
    font-size: 32px;
    font-weight: bold;
    padding: 13px 90px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px;
}

/* services-section */

#services-background {
    background-image: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.39) 48.44%,
            #011b48 100%
        ),
        url(./bglight.jpeg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.services-section-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-top: 83px;
    margin: auto;
    max-width: 1230px;
}

.services-img img {
    max-width: 600px;
}

.services-img {
    margin-top: 1.5rem;
}

.services-text {
    padding: 0 0 0 128px;
}

.services-text h3 {
    font-size: 40px;
    line-height: 65px;
    margin-bottom: 30px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-text p {
    font-size: 20px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    margin-bottom: 35px;
}

.services-text a {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 70px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    margin-top: 30px;
}

/* ROADMAP-start */

#roadmap-background {
    background: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.7) 48.44%,
            #011b48 100%
        ),
        url(./bgtree.jpeg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    margin: auto;
    padding: 60px 0px;
}

.background-zig-zag {
    background-image: url(./roadmap-side.png);
    background-repeat: no-repeat;
    position: relative;
    height: 71em;
    margin: auto;
    max-width: 375px;
    background-position: center;
    background-size: contain;
}

.main-content {
    width: 320px;
    color: #fff;
    position: relative;
}

.title {
    font-size: 30px;
    line-height: 32px;
    position: absolute;
    top: -15px;
    left: -102px;
    font-family: "Montserrat", sans-serif;
    color: #31d7fc;
}

.title::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: -21px;
    top: 10px;
    background: #31d7fc;
    border-radius: 50%;
    z-index: 1111;
}

.content-text {
    margin: 0px 0 0 0;
    position: absolute;
    top: 61px;
    left: 82px;
}

.content-text h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    color: #31d7fc;
    padding-bottom: 9px;
}

.content-text p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.main-content-two {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-two {
    position: absolute;
    top: 263px;
    left: -214px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #ff53cf;
}

.title-two::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: -21px;
    top: 10px;
    background: #ff53cf;
    border-radius: 50%;
    z-index: 1111;
}

.content-text-two {
    margin: 0px 0 0 0;
    position: absolute;
    top: 212px;
    left: 83px;
    text-align: left;
}

.content-text-two h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    /* color: #31D7FC; */
    padding-bottom: 9px;
    color: #ff53cf;
}

.content-text-two p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
}

.main-content-three {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-three {
    margin: 0 0 40px;
    position: absolute;
    top: 464px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #9969ff;
    bottom: 0;
    right: -28px;
}

.title-three::before {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    /* right: 23px; */
    top: 6px;
    background: #9969ff;
    border-radius: 50%;
    z-index: 1111;
    left: -22px;
    bottom: 0;
}

.content-text-three {
    margin: 0px 0 0 0;
    position: absolute;
    top: 394px;
    left: -125px;
    text-align: right;
    width: 450px;
}

.content-text-three h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: #9969ff;
    padding-right: 24px;
    padding-top: 7px;
}

.content-text-three p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
}

.main-content-four {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-four {
    font-size: 30px;
    line-height: 35px;
    margin: 0 0 40px;
    position: absolute;
    top: 640px;
    left: -219px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #ff4f4f;
}

.title-four::after {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    right: -21px;
    top: 12px;
    background: #ff4f4f;
    border-radius: 50%;
    z-index: 1111;
}

.content-text-four {
    margin: 0px 0 0 0;
    position: absolute;
    top: 591px;
    left: 75px;
    text-align: left;
    max-width: 463px;
}

.content-text-four h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: #ff4f4f;
}

.content-text-four p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
    padding-bottom: 12px;
}

.main-content-five {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-five {
    font-size: 30px;
    line-height: 35px;
    position: absolute;
    top: 840px;
    font-family: "Montserrat", sans-serif;
    color: rgba(51, 243, 70, 1);
    bottom: 0;
    right: -27px;
}

.title-five::before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    top: 10px;
    background: rgba(51, 243, 70, 1);
    border-radius: 50%;
    z-index: 1111;
    left: -22px;
    bottom: 0;
}

.content-text-five {
    margin: 0px 0 0 0;
    position: absolute;
    top: 807px;
    left: -112px;
    text-align: right;
    right: 239px;
}

.content-text-five h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: rgba(51, 243, 70, 1);
    padding-right: 24px;
    padding-top: 7px;
}

.content-text-five p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
}

.main-content-six {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-six {
    font-size: 30px;
    line-height: 35px;
    position: absolute;
    top: 1018px;
    left: -233px;
    font-family: "Montserrat", sans-serif;
    color: rgba(51, 128, 243, 1);
}

.title-six::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: -21px;
    top: 10px;
    background: rgba(51, 128, 243, 1);
    border-radius: 50%;
    z-index: 1111;
}

.content-text-six {
    margin: 0px 0 0 0;
    /* padding: 23px 95px 0 0px; */
    position: absolute;
    top: 965px;
    left: 75px;
    text-align: left;
    right: -12px;
}

.content-text-six h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: rgba(51, 128, 243, 1);
}

.content-text-six p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 60px;
}

/* F.A.Q */
#accordian-background {
    background-image: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.6) 48.44%,
            #011b48 100%
        ),
        url(./bglight.jpeg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /*background-position: 0;*/
}

.faq-set {
    text-align: center;
    margin: 0 auto;
    max-width: 1230px;
}

.accordion {
    background: transparent;
    color: #ffffff;
    cursor: pointer;
    padding: 25px 18px;
    width: 932px;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin: 31px 0 0px;
    line-height: 22px;
    font-weight: 400;
    background: rgba(1, 27, 72, 0.56);
    border: 1px solid #5e5e5e;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
}

.active,
.accordion:hover {
    background: linear-gradient(180deg, #5089f9 100%, #4f4bcf 0%);
    color: #ffffff;
    border: unset;
    border-radius: 8px 8px 0 0;
    margin-bottom: -2px;
    border: 2px solid #fff;
    border-bottom: unset;
}

.accordion:hover {
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 8px;
}

.active,
.panel {
    color: #ffffff;
}

.panel {
    display: none;
    background: linear-gradient(180deg, #5089f9 0%, #4f4bcf 100%);
    overflow: hidden;
    margin: 0;
    width: 932px;
    padding: 18px;
    margin: 0 auto;
    border-radius: 0 0 8px 8px;
    border: 2px solid;
    border-top: unset;
}

.panel p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    padding: 12px 0;
}

.panel p a {
    text-decoration: underline;
}

.accordion::after {
    /* background-image: url(./twitter.png); */
    font-size: 30px;
    margin-top: 0px;
    color: #ffff;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    transform: rotate(-360deg);
    font-family: "fontawesome";
    content: url(./Vector.png);
}

button.accordion.active:after {
    content: url(./Vector.png);
    transform: rotate(-180deg);
    font-size: 30px;
}

/* OUR-TEAM */
#team-footer-background {
    background-image: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.39) 48.44%,
            #011b48 100%
        ),
        url(./bglight.jpeg);
    background-position: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.OUR-TEAM {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 100px;
    padding-bottom: 54px;
    margin-top: 20px;
}

.live {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    padding: 55px 0px;
    max-width: 1230px;
    margin: auto;
}

.mobilecol {
    display: flex;
    max-width: 50%;
}

.live-img-1 {
    padding: 16px 10px 1px;
    width: 50%;
    border-radius: 8px 0 0 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0;
    border: 2px solid white;
    border-right: unset;
}

.live-img-1-2 {
    padding: 16px 10px 1px;
    width: 50%;
    border-radius: 0 8px 8px 0;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 -1px;
    border: 2px solid white;
    border-left: unset;
}

.live-img-1-3 {
    padding: 16px 10px 1px;
    width: 33%;
    border-radius: 0 8px 8px 0;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 -1px;
    border: 2px solid white;
    border-left: unset;
}

.live-img-3 {
    padding: 16px 10px 1px;
    width: 25%;
    border-radius: 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 0;
    border: 2px solid white;
}

.live-img-4 {
    padding: 16px 10px 1px;
    width: 25%;
    border-radius: 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0;
    border: 2px solid white;
}

.live-img-4 img {
    width: 100%;
    border-radius: 15px;
}
/* .live-img-1:hover {
    background: whitesmoke;
} */
.live-img-1 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-2 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-3 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-3 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-content {
    padding: 9px 0;
}

.content-absolute {
    position: absolute;
}

.live-img-1-content h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
}

.live-img-1-content p {
    font-size: 17px;
    line-height: 22px;
    padding-top: 5px;
    color: #ffffff;
    font-weight: 400;
    padding-top: 12px;
    padding-right: 16px;
}

.live-img-1-content span {
    float: right;
    font-weight: bold;
}

.live-img-1-content span img {
    width: 42%;
}

.team-role {
    font-size: 16px;
    font-weight: 300 !important;
    font-family: "Montserrat", sans-serif;
}

/* FOOTER */

.footer-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    /* padding-right: 24%; */
    /* padding-left: 8%; */
    padding-top: 100px;
    max-width: 1230px;
    margin: auto;
}

.footer-input {
    position: relative;
}

.footer-text img {
    padding: 10px 25px 30px 0px;
}

.footer-img {
    position: absolute;
    right: 15px;
    bottom: 12px;
}

.footer-text p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
}

.footer-text h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    padding: 21px 0;
    color: #ffffff;
}

.footer-input p {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    padding-bottom: 25px;
    color: #ffffff;
}

input.form-control {
    padding: 18px 70px 18px 17px;
    border-radius: 9px;
    width: 362px;
    font-size: 12px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-weight: bold;
}

/* FOOTER-LAST */

.empty-line {
    padding: 23px 0 10px;
    max-width: 1230px;
    margin: auto;
    border-top: 1px solid #343434;
}

.footer-main-last {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    /* padding-right: 18%; */
    /* padding-left: 8%; */
    max-width: 1230px;
    margin: auto;
}

.footer-text-1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #808080;
}
/* ====================================================================== */
/* ====================================================================== */
/* ======================================================================*/

/* MY COLLECTION */

.my-collection {
    text-align: center;
    padding: 100px 15px 30px;
    background: linear-gradient(
        180deg,
        #011b48 0%,
        rgba(20, 20, 20, 0.39) 48.44%,
        #011b48 100%
    );
}

.my-collection h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 92px;
    line-height: 112px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 15px;
}

.my-collection p {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
    color: #ffffff;
}

/* YETI-START */

.my-collection-yeti {
    padding: 3rem 10px 60px;
    border-radius: 8px;
    background: transparent;
    text-align: center;
    margin: 0px auto;
}

.live-my-collection {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: space-between;
    max-width: 415px;
    justify-content: center;
    margin: auto;
}

.live-my-collection h4 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: #ffffff;
    width: 415px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0 0 15px;
}

.live-my-collection h4 span {
    text-align: right;
    font-weight: 300;
    font-size: 18px;
    padding-left: 19px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    color: #ffffff;
}

.live-my-collection p {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 410px;
    padding: 10px 0 0 15px;
}

.live-my-collection p span {
    font-size: 20px;
    text-align: right;
    font-weight: 300;
    padding-left: 92px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    color: #ffffff;
}

.my-collection-yeti img {
    width: 415px;
    border-radius: 9px;
}

#customers th {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    background: linear-gradient(180deg, #1b315a 0%, #1b2d57 20%, #1b2854 75%);
    color: white;
}

tr {
    background: lightcoral;
}

/* tbody {
    border: 3px solid rgba(71, 86, 110, 0.8);
} */
table {
    border-collapse: separate;
    border-spacing: 0 18px;
    margin: 0 auto;
}

th {
    background-color: #4287f5;
    color: white;
}

th,
td {
    width: 150px;
    text-align: center;
    padding: 5px;
}

tr .custumers {
    border: 2px solid white;
}

.tableBg {
    background: linear-gradient(
        180deg,
        #011b48 0%,
        rgba(20, 20, 20, 0.39) 48.44%,
        #011b48 100%
    );
}

.customerbodyTable {
    background: rgba(14, 29, 52, 0.8);
    border: 1px solid #9b9bff;
    box-sizing: border-box;
    border-radius: 10px;
}

.customerTableheadInner {
    background: rgba(14, 29, 52, 0.8);
    border: 1px solid #9b9bff;
    box-sizing: border-box;
    border-radius: 7px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    align-content: center;
    padding: 20px 40px;
    /* padding: 20px 0px 20px 30px; */
}

p.headText {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    /* identical to box height, or 42px */
    color: #ffffff;
    text-align: center;
}

.customerTablerow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 20px 60px 20px 20px;
    margin: 20px 15px;
    border: 2px solid;
    border-image-source: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    border: 1px solid transparent;
    border-radius: 7px;
    background: linear-gradient(rgba(14, 29, 52), rgba(14, 29, 52)) padding-box,
        linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%) border-box;
}

p.paraText {
    text-align: center;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #a7a7a7;
}

.justDiv {
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.justDivLast {
    padding-left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.customerTable {
    max-width: 1232px;
    margin: auto;
}

p.headTextcenter {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
}

.headTextlast {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
}

p.paralast {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #a7a7a7;
    /* text-align: end; */
}

/* pop up start */
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.overlay-mint {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

.mint-visible {
    visibility: visible;
    opacity: 1;
}

.popup-width {
    max-width: 1230px;
    margin: auto;
}

.popup {
    margin: 70px auto;
    padding: 30px;
    max-width: 500px;
    position: relative;
    transition: all 5s ease-in-out;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    border: 2px solid #ffffff;
    border-radius: 10px;
    float: right;
}

.popup h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 25px;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
}

.popup .close:hover {
    color: #fff;
}

.popup .content {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 50px;
}

.mintbox-content {
    font-size: 18px;
}

a.barkbehance {
    background: #ffffff;
    border-radius: 100px;
    padding: 13px 50px;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #0b131f;
    margin-left: 1rem;
}

.innercontent {
    display: flex;
    align-items: center;
}

.innercontent img {
    padding: 5px 20px 5px 0;
}

a.bark {
    background: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 13px 20px;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #000000;
}

a.light {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 13px 20px;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #ffffff;
}

@media screen and (max-width: 700px) {
    .box {
        width: 70%;
    }

    .popup {
        width: 70%;
    }
}

header a {
    text-decoration: none;
}

header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    max-width: 1230px;
    margin: auto;
}

#brand {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
}

#brand a {
    color: #09c372;
}

.nav-list {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-list a {
    color: white;
}

.nav-list li {
    padding: 5px;
    margin-left: 10px;
}

.nav-list li:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

/* mobile menu */
#hamburger-icon {
    margin: auto 0;
    display: none;
    cursor: pointer;
}

#hamburger-icon div {
    width: 30px;
    height: 3px;
    background-color: #2fa0cc;
    margin: 6px 0;
    transition: 0.4s;
}

#hamburger-icon .social-icons {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

#hamburger-icon .mobile-btn {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

#hamburger-icon .mobile-menu .social-icons li {
    margin-bottom: 0px;
    padding: 10px;
}

.open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
    opacity: 0;
}

.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
}

.mobile-menu {
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    height: calc(100vh - 50px);
    width: 100%;
}

.open .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(
        180deg,
        #011b48 92%,
        rgba(20, 20, 20, 0.39) 57.44%,
        #011b48 7%
    );
    padding: 60px 30px;
}

.mobile-menu li {
    margin-bottom: 20px;
}

.mobile-menu li a {
    color: #fff;
}

.hide-wide {
    display: none;
}

.show-wide {
    display: block;
}

@media only screen and (max-width: 992px) {
    header nav {
        display: none;
    }

    #hamburger-icon {
        display: block;
    }
}

.collection-hero-background {
    background: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.7) 48.44%,
            #011b48 100%
        ),
        url(./bgtree.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.collection-item-background {
    background: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.7) 48.44%,
            #011b48 100%
        ),
        url(./bgsword.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.rarity-rank-button {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 45px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px;
}

/*
body {
	font-size: 17px;
	font-weight: 400;
	line-height: 28.9px;
}*/
