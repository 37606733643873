@media (max-width:1024px) {
	/* header */
	header {
		padding: 0 15px;
	}

	.nav-list li {
		padding: 5px;
		margin-left: 0px;
	}

	.nav-list a {
		color: white;
		font-size: 14px;
	}

	.ctn {
		padding: 10px 18px;
		margin: 0px 0 0 7px;
	}

	.navbar {
		padding: 30px 15px 15px;
	}

	.tableBg {
		padding: 0 15px;
	}

	.footer-main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.empty-line {
		max-width: 1000px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.footer-main-last {
		padding-left: 15px;
		padding-right: 15px;
	}

	.hero-section-main {
		padding: 30px 15px;
	}

	.services-section-main {
		padding: 83px 15px 0;
	}

	.live {
		padding: 55px 15px 0px;
	}

	.hero-section-text h2 {
		font-size: 45px;
	}

	.hero-section-text p {
		font-size: 17px;
	}

	.hero-text-img {
		padding: 30px;
	}

	.hero-text-img img {
		width: 300px;
	}

	.hero-img-time p {
		font-size: 16px;
	}

	.mint a {
		padding: 13px 40px;
	}

	.services-img img {
		max-width: 400px;
	}

	.services-text {
		padding: 0 0 0 60px;
	}

	.services-text h3 {
		margin-bottom: 0px;
	}

	.services-text p {
		font-size: 17px;
	}

	.popup-width {
		max-width: 990px;
	}

	.popup {
		max-width: 350px;
	}

	a.bark {
		font-size: 15px;
	}

	a.light {
		font-size: 15px;
	}
}

@media (max-width: 992px) {
	.hero-text-img {
		padding: 15px;
	}

	.hero-img-time p {
		font-size: 14px;
	}

	.priceTitle p span {
		font-size: 16px;
	}

	.priceTitle p {
		font-weight: 400;
		font-size: 20px;
	}

	.services-img img {
		max-width: 300px;
		height: 105%;
	}

	.hero-section-text h2 {
		font-size: 32px;
	}

	.hero-section-text p {
		font-size: 16px;
		line-height: 30px;
	}

	.hero-section-text {
		margin-top: 35px;
		padding-right: 22px;
	}

	.services-text {
		padding: 0px 0 0 30px;
	}

	.services-text p {
		font-size: 16px;
		line-height: 30px;
	}

	.title {
		font-size: 20px;
		left: -43px;
	}

	.title-two {
		left: -142px;
		font-size: 20px;
	}

	.title-three {
		font-size: 20px;
		right: 45px;
	}

	.title-four {
		left: -145px;
		font-size: 20px;
	}

	.title-five {
		font-size: 20px;
		right: 47px;
	}

	.title-six {
		font-size: 20px;
		left: -154px;
	}

	.main-content-six {
		width: 542px;
	}

	.accordion {
		width: 700px;
	}

	.panel {
		width: 700px;
	}

	.live-img-1-content h3 {
		font-size: 16px;
	}

	.live-img-1-content p {
		font-size: 15px;
		line-height: 20px;
	}

	input.form-control {
		width: 300px;
	}

	.popup-width {
		max-width: 733px;
	}

	.popup {
		max-width: 350px;
	}

	a.bark {
		font-size: 15px;
	}

	a.light {
		font-size: 15px;
	}
}

@media(max-width: 479px) {
	.hero-section-main {
		padding: 30px 15px;
		flex-direction: column;
	}

	.hero-section-text {
		margin-top: 35px;
		padding-right: 0px;
		text-align: center;
		margin-bottom: 60px;
	}

	.hero-section-text h2 {
		font-size: 36px;
	}

	.hero-section-text p {
		font-size: 16px;
		line-height: 30px;
		padding: 0px 0px 30px;
	}

	.hero-text-img {
		padding: 15px;
		max-width: 100%;
	}

	.hero-text-img img {
		width: 100%;
	}

	.hero-img-time p {
		font-size: 16px;
	}

	.priceTitle p {
		font-weight: 400;
		font-size: 20px;
	}

	.mint a {
		padding: 13px 40px;
	}

	/******Services ******/
	.services-section-main {
		flex-direction: column-reverse;
		padding: 83px 15px 0;
	}

	.services-text {
		padding: 0px 0px 60px 0px;
		text-align: center;
	}

	.services-img img {
		max-width: 100%;
		height: auto;
	}

	.services-text p {
		margin-bottom: 50px;
	}

	.services-text h3 {
		font-size: 36px;
	}

	/* team */
	.OUR-TEAM {
		padding-top: 60px;
	}
	/******roadmap ******/


	.background-zig-zag {
		background-image: url(./roadmap-\ mobile-back.png);
		height: 53em;
		max-width: 250px;
		background-position: center;
		background-size: contain;
		margin: auto;
	}

	.main-content {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.title {
		font-size: 14px;
		line-height: 21px;
		left: -36px;
		top: 6px;
	}

	.title::after {
		right: -21px;
		top: 5px;
	}

	.content-text {
		margin: 0px 0 0 0;
		position: absolute;
		top: 54px;
		left: 6px;
		width: 200px;
	}

	.main-content-two {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.content-text-two {
		margin: 0px 0 0 0;
		position: absolute;
		top: 161px;
		left: 65px;
		text-align: left;
		width: 250px;
	}

	.content-text p {
		font-size: 10px;
		line-height: 15px;
	}

	.title-two {
		font-size: 14px;
		line-height: 21px;
		left: -42px;
		top: 198px;
		width: 62px;
	}

	.title-two::after {
		right: 2px;
		top: 10px;
	}

	.main-content-three {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.content-text-three {
		margin: 0px 0 0 0;
		position: absolute;
		top: 289px;
		left: -49px;
		text-align: right;
		width: 268px;
	}

	.content-text-two h3 {
		font-size: 18px;
		line-height: 21px;
	}

	.content-text-two p {
		font-size: 10px;
		line-height: 15px;
	}

	.main-content-three {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.title-three {
		font-size: 14px;
		line-height: 21px;
		right: -33px;
		top: 336px;
		width: 62px;
	}

	.title-three::before {
		top: 14px;
		left: -15px;
	}

	.content-text-three h3 {
		font-size: 18px;
		line-height: 21px;
	}

	.content-text-three p {
		font-size: 10px;
		line-height: 15px;
	}

	.main-content-four {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.title-four {
		font-size: 14px;
		line-height: 21px;
		left: -41px;
		top: 472px;
		width: 62px;
	}

	.title-four::after {
		right: 0px;
		top: 8px;
	}

	.content-text-four {
		margin: 0px 0 0 0;
		position: absolute;
		top: 438px;
		left: 61px;
		text-align: left;
		max-width: 270px;
	}

	.content-text-four h3 {
		font-size: 18px;
		line-height: 21px;
	}

	.content-text-four p {
		font-size: 10px;
		line-height: 15px;
		padding-bottom: 2px;
	}

	.main-content-five {
		width: 270px;
		color: #fff;
		position: relative;
	}

	.content-text-five {
		margin: 0px 0 0 0;
		position: absolute;
		top: 588px;
		left: 0px;
		text-align: right;
		right: 48px;
	}

	.title-five {
		font-size: 14px;
		line-height: 21px;
		right: -32px;
		top: 611px;
		width: 62px;
	}

	.title-five::before {
		top: 13px;
		left: -15px;
	}

	.content-text-five h3 {
		font-size: 18px;
		line-height: 21px;
	}

	.content-text-five p {
		font-size: 10px;
		line-height: 15px;
	}

	.main-content-six {
		width: 270px;
	}

	.title-six {
		font-size: 18px;
		line-height: 21px;
		top: 742px;
		left: -68px;
		width: 72px;
	}

	.title-six {
		font-size: 14px;
		line-height: 21px;
		top: 742px;
		left: -50px;
		width: 62px;
	}

	.title-six::after {
		right: 3px;
	}

	.content-text-six {
		margin: 0px 0 0 0;
		position: absolute;
		top: 713px;
		left: 45px;
		text-align: left;
		right: -12px;
		width: 300px;
	}

	.content-text-six h3 {
		font-size: 18px;
		line-height: 21px;
	}

	.content-text-six p {
		font-size: 10px;
		line-height: 15px;
	}

	.accordion {
		max-width: 360px;
	}

	.panel {
		max-width: 360px;
	}

	.live {
		padding: 55px 15px 0px;
		flex-direction: column !important;
	}

	.mobilecol {
		display: flex;
		max-width: 100%;
		flex-direction: column;
	}

	.live-img-1 {
		padding: 16px 10px 1px;
		width: 100%;
		border-radius: 8px;
		background: linear-gradient(180deg, #5092FF 0%, #4F4BCF 100%);
		margin: 0 15px 0 0;
		border: 2px solid white;
	}

	.live-img-1-2 {
		padding: 16px 10px 1px;
		width: 100%;
		border-radius: 8px;
		background: linear-gradient(180deg, #5092FF 0%, #4F4BCF 100%);
		margin: 10px 15px 10px 0;
		border: 2px solid white;
	}

	.live-img-1-3 {
		padding: 16px 10px 1px;
		width: 100%;
		border-radius: 8px;
		background: linear-gradient(180deg, #5092FF 0%, #4F4BCF 100%);
		margin: 0 15px 15px 0;
		border: 2px solid white;
	}

	.hide-wide {
		display: block;
		width: 85%;
	}

	.show-wide {
		display: none;
	}

	.live-img-3 {
		width: 100%;
		margin: 30px 0;
	}

	.live-img-4 {
		width: 100%;
	}

	.footer-main {
		display: flex;
		flex-direction: column;
	}

	.footer-text {
		text-align: center;
	}

	.footer-input {
		position: relative;
		width: 100%;
	}

	input.form-control {
		width: 100%;
	}

	.empty-line {
		padding: 60px 0 10px;
		max-width: 1230px;
		margin: auto;
		border-top: unset;
	}

	.footer-main-last {
		flex-direction: column;
		text-align: center;
	}

	.footer-text-1 {
		margin-bottom: 15px;
	}

	.popup-width {
		max-width: unset;
	}

	.popup {
		width: 90%;
		float: unset;
	}

	a.bark {
		font-size: 12px;
		padding: 13px 15px;
	}

	a.light {
		font-size: 12px;
		padding: 13px 15px;
	}

	a.barkbehance {
		padding: 13px 40px;
		font-size: 18px;
	}

	/*body {*/
	/*	font-size: 14px;*/
	/*	line-height: 22px;*/
	/*}*/
}

@media (max-width:360px) {

	.accordion {
		max-width: 340px;
	}

	.panel {
		max-width: 340px;
	}

}

@media (max-width:320px) {
	.open .mobile-menu {
		padding: 60px 5px;
	}

	.ctn {
		padding: 10px 14px;
		margin: 0px 0 0 7px;
	}

	img.logo {
		width: 90%;
	}

	.accordion {
		max-width: 290px;
	}

	.panel {
		max-width: 290px;
	}

	.title {
		font-size: 12px;
		line-height: 21px;
		left: -26px;
		top: 6px;
	}

	.title-two {
		font-size: 10px;
		line-height: 21px;
		left: -24px;
		top: 198px;
		width: 43px;
	}

	.title-three {
		font-size: 10px;
		line-height: 21px;
		right: -16px;
		top: 336px;
		width: 44px;
	}

	.title-four {
		font-size: 10px;
		line-height: 21px;
		left: -26px;
		top: 472px;
		width: 45px;
	}

	.title-four::after {
		right: 2px;
		top: 8px;
	}

	.title-five {
		font-size: 12px;
		line-height: 21px;
		right: -21px;
		top: 611px;
		width: 52px;
	}

	.title-six {
		font-size: 10px;
		line-height: 21px;
		top: 742px;
		left: -31px;
		width: 43px;
	}
}
